@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

.App {
  background: white;
}



@media only screen and (max-device-width: 2000px) {
  html {
    font-size: 16px
  }
}

/* ----------- iPhone 6+ ----------- */
/* Portrait and Landscape */
@media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 3) {
  html {
    font-size: 12px !important
  }
}

/* ----------- iPad ----------- */
/* Portrait and Landscape */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {
  html {
    font-size: 13px !important
  }
}